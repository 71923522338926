import React, { useState } from "react";
import { Container } from "react-bootstrap";
import iso from "./pdf/YHE ISO9001_2015 Certification.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const Iso = () => {
  const [numPages, setNumPages] = useState(null);
  var [pageNumber, setPageNumber] = useState(1);
  function goToPrevPage() {
    if (pageNumber > 1) {
      pageNumber--;
      setPageNumber(pageNumber);
    }
  }
  function goToNextPage() {
    pageNumber++;
    setPageNumber(pageNumber);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <React.Fragment>
      <Container className="pdfContainer">
        <h1
          style={{ color: "#f9a91a" }}
          className="h1-responsive font-weight-bold text-center  mt-5 mb-3"
        >
          ISO Certificate
        </h1>
        <div className="mydoc">
          <Document file={iso} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p></p>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Iso;
