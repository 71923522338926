import React, { Component } from "react";
import Services from "../services/services";
import { Tab, Row, Container } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, brands } from "@fortawesome/fontawesome-svg-core/import.macro";

class ourServices extends Component {
  state = {
    ourServices: [{ training: [] }],
  };

  render() {
    return (
      <div className="bg-black">
        {" "}
        <Container className="ourSevicesContainer">
          <h1 class="my-5 h1-responsive font-weight-bold text-center text-white">
            Our Services
          </h1>

          <Tab.Container
            id="list-group-tabs-example"
            defaultActiveKey="#Enviromental"
          >
            <div class="h4">
              <ListGroup horizontal>
                <ListGroup.Item
                  action
                  href="#Enviromental"
                  aria-selected="true"
                >
                  <FontAwesomeIcon icon={brands("envira")} /> Environmental
                  monitoring, measurements & EIA
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#Training">
                  <FontAwesomeIcon icon={solid("chalkboard-teacher")} />{" "}
                  Training & Consultion
                </ListGroup.Item>
                <ListGroup.Item action href="#IMS">
                  <FontAwesomeIcon icon={solid("pen")} /> IMS & ISO Audits
                  Preparation
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#Marine">
                  <FontAwesomeIcon icon={solid("ship")} /> Marine Consultion
                </ListGroup.Item>
                <ListGroup.Item action href="#itSolutions">
                  <FontAwesomeIcon icon={solid("camera")} /> ship IT Solutions &
                  Security Systems
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#supply">
                  <FontAwesomeIcon icon={solid("head-side-mask")} /> Supply
                  Chain & General Supplies
                </ListGroup.Item>
                <ListGroup.Item action href="#Staffing">
                  <FontAwesomeIcon icon={solid("users")} /> Staffing Services
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#Elevators">
                  <FontAwesomeIcon icon={solid("caret-up")} /> Vertical
                  Transportation
                </ListGroup.Item>
                <ListGroup.Item action href="#facilities">
                  <FontAwesomeIcon icon={solid("calculator")} />
                  Facilities Management
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#fireFighting">
                  <FontAwesomeIcon icon={solid("fire-extinguisher")} />{" "}
                  Firefighting, Alarm Systems & CCTV Systems
                </ListGroup.Item>
              </ListGroup>
            </div>

            <Row className="text-center text-white">
              <Tab.Content>
                <Services
                  text={
                    <div>
                      <li>
                        Upstream, midstream, and downstream oil and gas industry
                      </li>
                      <li>Production and engineering</li>
                      <li>Drilling and workover</li>
                      <li>Occupational health and safety and environment</li>
                      <li>Risk management for supervisors and managers </li>
                      <li>HSSE induction for workers and much more...</li>
                    </div>
                  }
                  event="#Training"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <li>Air Pollution Measurements</li>
                      <li>Analysis of various water pollutants</li>
                      <li>
                        Measuring physical and chemical properties of soil.
                      </li>
                    </div>
                  }
                  event="#Enviromental"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <li>ISO 9001 (quality assurance)</li>
                      <li>ISO 14001(environmental management)</li>
                      <li>OHSAS 18001 (health and safety management)</li>
                      <li>ISO 22000(food safety management)</li>
                    </div>
                  }
                  event="#IMS"
                />
                <Services
                  text={
                    <div>
                      <div className="row">
                        <div className="col-md">
                          <li>Marine Consultancy</li>
                          <li>Marine surveys </li>
                          <li>Bunker Surveys</li>
                          <li>Tanker Surveys</li>
                          <li>On hire/off hire surveys</li>
                        </div>
                        <div className="col-md">
                          <li>
                            Flag state inspections by individual approved
                            inspectors
                          </li>{" "}
                          <li>Hazardous Wastes Management</li>
                          <li>
                            Prepare EIA studies for different projects in
                            different governorates
                          </li>{" "}
                          <li>sources in the system.</li>
                        </div>
                      </div>
                    </div>
                  }
                  event="#Marine"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <div className="row">
                        <div className="col-md">
                          <li>Network design</li>
                          <li>Systems design</li>
                          <li>Network implementation</li>
                          <li>system installing</li>
                        </div>
                        <div className="col-md">
                          <li>CCTV design</li>
                          <li>System troubleshooting</li>{" "}
                          <li>System Evaluation</li>
                          <li>Integrating systems</li>
                        </div>
                      </div>
                    </div>
                  }
                  event="#itSolutions"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <div className="row">
                        <div className="col">
                          <li>Fire alarm systems </li>
                          <li>Fire systems</li>
                        </div>
                        <div className="col">
                          <li>Fire treatments </li>
                          <li>Supplying and installing </li>
                        </div>
                      </div>
                    </div>
                  }
                  event="#fireFighting"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <li>Provides specialist manpower resources</li>
                      <li>
                        {" "}
                        General Supplies & Trademarks and Facilities Management
                        Services.
                      </li>
                      <li>
                        Development of sustainable energy-efficient
                        practices,application of renewable energy.
                      </li>
                    </div>
                  }
                  event="#supply"
                />

                <Services
                  text={
                    <div>
                      {" "}
                      <li>IT staffing</li>
                      <li> Accounting, financial, and managerial staffing</li>
                      <li>Recruitment process outsourcing</li>
                      <li>Technical and industrial staffing</li>
                      <li>Offshore requirements</li>
                    </div>
                  }
                  event="#Staffing"
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      We have a workforce that is spread all over Egypt/Overseas
                      with just one mission to fulfil - finding the right people
                      to meet your specific needs. You may need people with a
                      certain level of experience in a particular domain, or in
                      a specific location. No matter what you require, our
                      competent staffing services division will find people who
                      will meet the unique needs of your company such as
                      consultation, outsourcing and placement.
                    </p>
                  }
                />
                <Services
                  event="#Elevators"
                  text={
                    <div>
                      {" "}
                      <h4>Design Deliverables:</h4>
                      <ul>
                        <li>Design Drawings, Specifications, Cost Estimates</li>
                        <li>
                          Equipment Selection, Multi-disciplinary Coordination
                        </li>
                        <li>
                          Compliance with Applicable Codes, Standards and
                          References
                        </li>
                        <li>Technical and industrial staffing</li>
                        <li>
                          Solving logistical problems through design of proper
                          Vertical Transportation Systems.
                        </li>
                      </ul>
                      <h4 style={{ textWeight: "bold" }}>Assessments</h4>
                      <ul>
                        <li>Elevator/Escalator Upgrades and Replacements</li>
                        <li>
                          Existing Equipment Inspections and Recommendations
                        </li>
                        <li>
                          New Product Assessments, Studies, and White Papers
                        </li>
                        <li>
                          Code and ADA Analyses for Vertical Transportation
                          Equipment
                        </li>
                      </ul>
                      <h4 style={{ textWeight: "bold" }}>Construction Phase</h4>
                      <ul>
                        <li>Schedule Review</li>
                        <li>
                          Responses to Requests for Information/ Copy (RFI/RFC)
                        </li>
                        <li>Shop Drawing/Submittal Review</li>
                        <li>Change Order Review</li>
                        <li>
                          Field Inspections, Acceptance Inspections,
                          Construction Administration
                        </li>
                        <li>
                          Progress Visits, Field Installation Assessment,
                          Quality Reviews{" "}
                        </li>
                        <li>End of Construction Punch List and Follow-up</li>
                      </ul>
                      <h4 style={{ textWeight: "bold" }}>
                        Pedestrian Flow Analysis
                      </h4>
                      <ul>
                        <li>Vertical Transportation Simulations</li>
                        <li>
                          Elevator Average Wait Time Calculations, Peak Time
                          Projections
                        </li>
                      </ul>
                    </div>
                  }
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      YHE has an experts in the specialized field of vertical
                      transportation, you can count on us to deliver affordable,
                      customized, and cohesive solutions that meet your client’s
                      specific needs. Yellow Helmet Egypt full range of
                      services:
                    </p>
                  }
                />
                <Services
                  event="#facilities"
                  text={
                    <div>
                      {" "}
                      <h5 class="black-text h4">
                        <li className="m-2">
                          <strong>Overall management of facilities:-</strong>{" "}
                          YHE help organizations manage many aspects of their
                          facilities, from preventive maintenance on equipment
                          to daily cleaning. These services are often
                          extensible, allowing businesses to get as much
                          assistance as they need to keep their facilities
                          running smoothly.{" "}
                        </li>

                        <li className="m-2">
                          <strong>Security Services:-</strong> Keeping
                          properties and staff safe and protected is a top
                          priority and some outside support is always needed.
                          Surveillance, access control “CCTV”, and management
                          alerts all factors to safeguarding a work area and are
                          an often overlooked component of facility management.
                        </li>

                        <li className="m-2">
                          <strong>Maintenance Projects:-</strong> YHE
                          Maintenance teams perform several tasks over the
                          course of a single week, and all projects whether
                          requiring small improvements or massive overhauls need
                          to be well prepared and effectively managed. Such
                          maintenance programs are designed and operated by
                          Facilities Management firms as well as helping
                          businesses see them through to completion.
                        </li>
                        <li className="m-2">
                          <strong>Quality Control:-</strong> YHE use a range of
                          procedures and services such as preventive
                          maintenance, regular checks, and process analysis to
                          ensure that the final products of a company meet the
                          highest quality requirements. Maintaining machines and
                          systems in top-of-the-run condition is essential to
                          quality assurance, as are regular equipment
                          inspections.
                        </li>
                        <li className="m-2">
                          <strong>Real Estate Maintenance:- </strong>YHE
                          specialize in the care and management of real estate.
                          This form of service focuses on keeping property and
                          buildings in good condition, incorporating routine
                          maintenance, preventive and reactive repairs, and
                          daily cleaning.
                        </li>
                        <li className="m-2">
                          {" "}
                          <strong>IT and Networking:-</strong> YHE maintain
                          client’s IT network along with its physical machines
                          and systems, in order to keep operations going.
                          Network monitoring, maintenance, and security services
                          help keep the IT infrastructure of a facility in
                          reliable working shape while avoiding breaches that
                          could compromise the entire company.
                        </li>
                        <li className="m-2">
                          <strong>Cleaning Services :-</strong> Keeping
                          facilities clean is a key component of management of
                          facilities, and YHE offer cleaning and cleaning
                          services. Such programs can provide regular
                          comprehensive cleaning – disinfections or day-to-day
                          custodial work.
                        </li>
                        <li className="m-2">
                          <strong>Support Services:-</strong> YHE provide
                          various support services, such as maintenance
                          facilities, training, landscaping, and many more.
                        </li>
                      </h5>
                    </div>
                  }
                />
              </Tab.Content>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    );
  }
}

export default ourServices;
