import React from "react";
import "./my.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper";
import { Container } from "react-bootstrap";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/effect-fade";
import medserv from "../../assets/img/logos/medserv.png";
import brothers from "../../assets/img/logos/3brothers.png";
import aca from "../../assets/img/logos/aca.png";
import alstom from "../../assets/img/logos/alstom.jpg";
import animatics from "../../assets/img/logos/animatics.jpg";
import atlantic from "../../assets/img/logos/atlantic.jpg";
import tradeacc from "../../assets/img/logos/tradeacc.jpg";
import border from "../../assets/img/logos/border.jpg";
import ecotrust from "../../assets/img/logos/ecotrust.jpg";
import bankabc from "../../assets/img/logos/bankabc.png";
import cocacolaBot from "../../assets/img/logos/cocacola bottling.png";
import cocacola from "../../assets/img/logos/cocacola.png";
import evergrow from "../../assets/img/logos/evergrow.png";
import fairmont from "../../assets/img/logos/fairmont.png";
import globoil from "../../assets/img/logos/globoil.png";
import ferro from "../../assets/img/logos/ferro.png";
import gfc from "../../assets/img/logos/gfc.png";
import shield from "../../assets/img/logos/shield.png";
import gvos from "../../assets/img/logos/gvos.png";
import total from "../../assets/img/logos/total.png";
import uber from "../../assets/img/logos/uber.png";
import corteva from "../../assets/img/logos/corteva.webp";
import eni from "../../assets/img/logos/eni.svg";
const OurClients = () => {
  return (
    <Container className="teamContainer ">
      <Swiper
        modules={[EffectFade, Pagination]}
        spaceBetween={60}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          720: {
            width: 640,
            slidesPerView: 2,
          },
          // when window width is >= 768px
          830: {
            width: 1000,
            slidesPerView: 4,
          },
        }}
        slidesPerView={1}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={medserv} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={brothers} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={aca} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={alstom} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={animatics} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={atlantic} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={bankabc} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={border} alt="" />
        </SwiperSlide>
        <SwiperSlide className="simg">
          {" "}
          <img className="img" width={200} src={cocacolaBot} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={cocacola} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={corteva} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={ecotrust} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={eni} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={evergrow} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={fairmont} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={ferro} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={gfc} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={globoil} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={gvos} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={shield} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={total} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={tradeacc} alt="" />
        </SwiperSlide>
        <SwiperSlide className="">
          {" "}
          <img className="img" width={200} src={uber} alt="" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default OurClients;
