import React from "react";
import "./my.css";
import NavBar from "../navBar/navBar";
const firstPage = () => {
  return (
    <div className="lol clientsPageContainer">
      <NavBar />

      <div className="blockquote-wrapper">
        <div className="blockquote">
          <h1 className="h1-responsive  textBorders ">
            <span style={{ color: "white" }}> Well</span> Done{" "}
            <span style={{ color: "white" }}>, is better than well </span> Said
          </h1>
          <h4 className="text-white text-center">&mdash;Yellow Helmet Egypt</h4>
        </div>
      </div>
    </div>
  );
};

export default firstPage;
