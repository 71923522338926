import React from "react";
import mission from "../../assets/img/mission2.png";
import vision from "../../assets/img/vission1.png";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./styles.css";
import Fade from "react-reveal/Fade";
const Vision = () => {
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <Fade Top>
        <Container className="visionContainer">
          <h1 className="h1-responsive text-center mb-5 font-weight-bold text-center myYellow">
            Vision and Mission
          </h1>

          <div className="row wow fadeIn ">
            <div className="col-6-md mx-auto text-center ">
              <img
                src={vision}
                className="img-vision"
                alt="hse vision"
                width={250}
              />
              <h2 className="h2-responsive font-weight-bold mt-3 text-center">
                SAVING PEOPLE LIVES
              </h2>
              <h3 className="h3-responsive">
                by targeting Zero Incidents and becoming the leading company in
                training & consultation
              </h3>
            </div>

            <div className="col-6-md mx-auto text-center">
              <img
                src={mission}
                className="img-vision "
                style={{
                  width: "280px",
                  marginLeft: "50px",
                  marginBottom: "-10px",
                }}
                alt="hse mission"
              />

              <h2 className="h2-responsive font-weight-bold text-center myYellow">
                REPS
              </h2>
              <h3 className="h3-responsive text-center">
                <strong className="myYellow">R</strong>epresent
              </h3>
              <h3 className="h3-responsive ">
                <strong className="myYellow">E</strong>ducate
              </h3>
              <h3 className="h3-responsive">
                <strong className="myYellow">P</strong>romote
              </h3>
              <h3 className="h3-responsive">
                <strong className="myYellow">S</strong>upply
              </h3>
              <h3 className="h3-responsive">
                All industries to maintain high standards for service and
                consultants to be always able to bring the best team of senior
                consultants to bear on every single project.
              </h3>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
};

export default Vision;
