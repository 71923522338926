import React, { Component } from "react";
import Services from "../services/services";
import { Tab, Row, Container } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

class ourServices extends Component {
  state = {
    ourServices: [{ training: [] }],
  };
  render() {
    return (
      <div className="bg-black">
        {" "}
        <Container className="ourSevicesContainer">
          <h1 class="my-5 h1-responsive font-weight-bold text-center text-white">
            Our Partners Services
          </h1>

          <Tab.Container
            id="list-group-tabs-example"
            defaultActiveKey="#tech_serve"
          >
            <div class="h4 ">
              <ListGroup horizontal>
                <ListGroup.Item action href="#tech_serve">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("cogs")}
                  />{" "}
                  TechServe
                </ListGroup.Item>
                <ListGroup.Item action href="#compliance">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("list-check")}
                  />
                  Compliance
                </ListGroup.Item>

                <ListGroup.Item action href="#drillsafe">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("snowplow")}
                  />
                  DrillSafe
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#proact">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("running")}
                  />
                  ProAct
                </ListGroup.Item>

                <ListGroup.Item action href="#framework">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("handshake")}
                  />{" "}
                  Framework
                </ListGroup.Item>
                <ListGroup.Item action href="#risk_toolkit">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("toolbox")}
                  />{" "}
                  Risk Toolkit
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#workshops">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("chalkboard-teacher")}
                  />
                  Workshops
                </ListGroup.Item>
                <ListGroup.Item action href="#it_solutions">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("laptop-code")}
                  />{" "}
                  It solutions & Digitalisation
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#supply_chain">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("boxes")}
                  />
                  Supply Chain Support
                </ListGroup.Item>
                <ListGroup.Item action href="#integrated">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("users")}
                  />
                  Integrated Management solutions
                </ListGroup.Item>
              </ListGroup>
              <ListGroup horizontal>
                <ListGroup.Item action href="#Iso">
                  <FontAwesomeIcon
                    style={{ marginRight: "2px" }}
                    icon={solid("check")}
                  />
                  ISO Certification
                </ListGroup.Item>
              </ListGroup>
            </div>

            <Row className="text-center text-white">
              <Tab.Content>
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      Through our JVs , TechServe provides Clients with a
                      comprehensive suite of studies, programmes and advice
                      aimed at either specific needs or as part of a tailored
                      work scope. A selection of typical services is listed
                      below.
                    </p>
                  }
                  text={
                    <div>
                      <li>Process and Engineering studies </li>
                      <li>Process Safety and Function Management </li>
                      <li>Sensitivity studies and demonstration of ALARP.</li>
                      <li>
                        PFEER, EER and ERP linked to Major Accident profiles.
                      </li>
                      <li>
                        HazId, PHA, Bowtie, HazOp, SIL, LOPA, FMEA/FMECA and
                        RBI/RBM.
                      </li>
                      <li>PHSSER and SHEAMS assessments</li>
                      <li>
                        FEA, QRA, Occupied Buildings and sensitivity Analysis
                      </li>
                      <li>Fire and Blast Modelling</li>
                      <li>SECE/SCA and Performance Standards development</li>
                      <li>
                        Concept, FEED and Detailed Design support at both
                        studies and individual secondment level
                      </li>
                      <li>
                        HSE Case. Combined Operations and SimOps submission
                      </li>
                      <li>
                        Seveso III Directive covering all aspects of the
                        Legislation and providing full GAP assessment from
                        previous COMAH submissions.
                      </li>
                      <li>Decommissioning studies and support services</li>
                      <li>Risk Assessment Framework for Renewable Energy</li>
                      <li>
                        Technical authorship including manuals, operating
                        procedures and instructions.
                      </li>
                    </div>
                  }
                  event="#tech_serve"
                />

                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      Through our COMPLIANCE JVs work programmes, we can offer a
                      range of services depending on a Clients requirement.{" "}
                      <br />
                      <br />
                    </p>
                  }
                  text={
                    <div className="row">
                      <div className="col-6">
                        <p style={{ textAlign: "center" }}>
                          HSE CASE AND REGULATORY SUBMISSIONS:
                        </p>
                        <li>Offshore Safety Directive (Safety Case etc.) </li>
                        <li>
                          Regulations 2015. HSE Cases for Offshore Facilities
                        </li>
                        <li>Directive 2012/18/EU SEVESO III for Onshore </li>
                        <li>facilities </li>
                        <li>IADC HSE Case guidance of Onshore and Offshore </li>
                        <li>Mobile Drilling Units </li>
                        <li>HSE Case Thorough Review </li>{" "}
                        <li>Oil Pollution Emergency Plans (OPEP) </li>{" "}
                        <li>Waste Management </li>
                        <li>UK Regulatory Compliance Gap Analysis </li>{" "}
                        <li>REACH Requirements </li>{" "}
                        <li>CDM submissions and support </li>{" "}
                        <li>Operational Permits and submissions</li>
                      </div>

                      <div className="col-6">
                        <p style={{ textAlign: "center" }}>
                          ASSURANCE AND VERIFICATON:
                          <br />
                          <br />
                        </p>

                        <li>SECE Verification schemes </li>
                        <li>Integrity Inspection </li>
                        <li>Certification support</li>
                        <li>CE Marking and Technical File</li>
                        <li>Asset performance and benchmarking</li>
                        <li>Brownfield regeneration support </li>
                        <li>
                          RBI and RBM support packages (linked to our partner
                          companies)
                        </li>
                        <li>
                          Functional Safety assessments, audits and verification
                          (IEC61511){" "}
                        </li>
                        <li>ATEX and DSEAR</li>
                        <li>Due diligence</li>
                      </div>
                    </div>
                  }
                  event="#compliance"
                />

                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      DrillSafe provides Clients with a comprehensive suite of
                      studies, programmes and advice aimed at either specific
                      needs or as part of a tailored work scope. A selection of
                      typical services offered within the JVs is listed below.
                    </p>
                  }
                  text={
                    <div>
                      <li>
                        HSE Cases, SECE Performance standards and verification
                        Schemes (IADC and Regional Regulatory requirements).{" "}
                      </li>{" "}
                      <li>
                        IADC HSE Case development, education and lifecycle
                        support programmes (CARE, CONNECT and CALM).
                      </li>{" "}
                      <li>HSE Case Thorough Reviews.</li>{" "}
                      <li>
                        Major Accident Hazard Risk Assessment (MAHRA) with
                        Bowtie analysis.{" "}
                      </li>{" "}
                      <li>HazId (with register) and Compartment surveys.</li>{" "}
                      <li>
                        Dropped object reviews and plans (red zone working,
                        etc), DWOP, SimOps, ComOPs and Bridging reviews and
                        documentation.
                      </li>{" "}
                      <li>Well Intervention, Well testing and workover.</li>{" "}
                      <li>
                        Regulatory compliance, permits and Rig Intake support.
                      </li>{" "}
                      <li>
                        Safety Critical Task Analysis (SCTA) and Human Factors.
                      </li>{" "}
                      <li>PFEER, EERA, ESSA and ERP studies.</li>
                      <li>
                        HazOp, FTA, FMEA, FMECA and LOPA/SIL Studies supporting
                        ballast, bilge, jacking systems, DP etc.
                      </li>{" "}
                      <li>FEA, QRA and sensitivity Analysis (ALARP/ALARA). </li>{" "}
                      <li>Fire and Blast Modelling.</li>{" "}
                      <li>HSE Audits and Inspections.</li>{" "}
                      <li>
                        Policy, Standards and Procedure reviews and development.
                      </li>{" "}
                      <li>
                        Warranty surveys, statutory rules, regulations and
                        compliance inspection.
                      </li>
                      <li>
                        ISM integrated management systems, ISPS systems leading
                        to certification and MLC 2006.
                      </li>{" "}
                      <li>Environmental Impact Assessments.</li>{" "}
                      <li>Decommissioning studies and support services. </li>
                      <li>
                        Accident, Loss Investigation and litigation support.
                      </li>{" "}
                      <li>
                        Technical authorship including manuals, operating
                        procedures and instructions.{" "}
                      </li>
                      <li>Bespoke Training and education packages.</li>
                    </div>
                  }
                  event="#drillsafe"
                />

                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      ProAct is a service offered within the JVs that can
                      operate on a standalone basis or draw from the expertise
                      provided in the HSE and Technical Consulting service base
                      across a wide range of Industries on a Global footing.{" "}
                    </p>
                  }
                  text={
                    <div>
                      <li>
                        Accident and Loss investigation, reporting and
                        Regulatory breaches
                      </li>
                      <li>
                        Workplace and occupational safe systems of work
                        including layout and access/egress
                      </li>
                      <li>Risk Assessment</li>
                      <li>Risk Assessment with BowTie Analysis</li>
                      <li>Fire Risk Assessment</li>
                      <li>COSHH assessments and systems</li>
                      <li>HSE Management systems development and audit</li>
                      <li>LOLER and PUWER assessments</li>
                      <li>Working at Heights assessments</li>
                      <li>Noise assessment</li>
                      <li>Asbestos assessment</li>
                      <li>Occupational Safety Training programmes</li>
                      <li>Audits and Inspections</li>
                      <li>
                        LEED (Leadership in Energy and Environmental Design)
                        Assessments as part of the ESOS Scheme
                      </li>
                      <li>Handling systems assessment</li>
                      <li>
                        Safety Assurance Plans, Safety Case Acceptance and Route
                        Acceptance.
                      </li>
                      <li>Contractor Assurance Cases</li>
                      <li>Line Operations Safety Audit (LOSA)</li>
                      <li>CE Marking technical file</li>
                      <li>Machinery layout and guarding</li>
                      <li>Technical authorship</li>
                    </div>
                  }
                  event="#proact"
                />

                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      Framework is a service offered within the JVs that can
                      operate on an Individual basis or draw from the expertise
                      provided in the HSE and Technical Consulting service base
                      across a wide range of Industries on a Global footing.{" "}
                    </p>
                  }
                  text={
                    <div>
                      <li>
                        Accident and Loss investigation, reporting and
                        Regulatory breaches.
                      </li>
                      <li>Workplace and occupational safe systems of work.</li>
                      <li>
                        Slips, trips and falls in commercial as well as
                        Industrial settings.
                      </li>
                      <li>COVID 19 Secure assessments and Investigations.</li>
                      <li>Fire, explosion and risk consequence failures.</li>
                      <li>
                        HSEQ and Engineering failures (Concept – Design –
                        Operation – Decommissioning).
                      </li>
                      <li>Claims management and loss adjusting support.</li>
                      <li>
                        Expert Witness across a wide range of Industries and
                        specialisms.
                      </li>
                      <li>
                        Litigation and Arbitration (Case investigation, file,
                        report compilation and representation).
                      </li>
                      <li>
                        Representation at Legal, Tribunal and Regulatory
                        settings.
                      </li>
                      <li>Due Diligence</li>
                    </div>
                  }
                  event="#framework"
                />
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      Integrated risk model aimed at providing Clients with
                      qualitative, semi quantitative, and quantitative
                      assessments across a wide range of project requirements
                      using Industry validated techniques and models.{" "}
                    </p>
                  }
                  text={
                    <div>
                      {" "}
                      <li>
                        An integral part of JVs services is the after care and
                        education of such areas as HSE Cases, SECE/SCA barrier
                        management and Performance Standard assurance. With this
                        in mind and part of the Risk Toolkit™ several programmes
                        have been developed based on experience, compliance and
                        regional challenges.
                      </li>
                      <li>
                        CALM© - The CALM (Communication And Lifecycle
                        Management) programme is an 8-step process aimed at
                        providing a Client with a compliant HSE Case and a
                        lifecycle programme for its management.
                      </li>
                      <li>
                        CARE©, (Critical Activities and Response to Emergencies)
                        CARE cards provide responsible persons a card that
                        contains concise information relating to what is deemed
                        a SCA/SECE and their required responsibilities.
                      </li>
                    </div>
                  }
                  event="#risk_toolkit"
                />
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      YHE have established workshop training in the following
                      areas:{" "}
                    </p>
                  }
                  text={
                    <div className="row">
                      <div className="col-6">
                        <li>HSE Inspectors</li>
                        <li>Fundamentals of Industrial Hygiene</li>
                        <li>OSHA Training</li>
                        <li>Behavior Based Safety Specialist (BBS)</li>
                      </div>
                      <div className="col-6">
                        <li>First Aid at Work Course</li>
                        <li>Basic - Specialist HSE training courses</li>
                        <li>Fire Safety Management</li>
                        <li>Facilities Management Training </li>
                      </div>
                    </div>
                  }
                  event="#workshops"
                />
                <Services
                  para={<p style={{ textAlign: "center" }}> </p>}
                  text={
                    <div>
                      <div className="row">
                        <div className="col">
                          <li>Network Design</li>
                          <li>Systems Design</li>
                          <li>Network implementation</li>
                        </div>
                        <div className="col">
                          <li>System Installing</li>
                          <li>Digitalisation design</li>
                        </div>
                        <div className="col">
                          <li>System troubleshooting</li>
                          <li>Integrating systems</li>
                        </div>
                      </div>
                    </div>
                  }
                  event="#it_solutions"
                />
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      As an integral part of the JVs YHE and partners have
                      extensive experience in Management systems across a wide
                      range of Industrial and Regulation settings.{" "}
                    </p>
                  }
                  text={
                    <div>
                      <li>Marine</li>
                      <li>Environmental and renewable</li>
                      <li>Waste</li>
                      <li>Manufacturing and process</li>
                      <li>
                        Infrastructure <br />
                        <br />
                      </li>
                      <p style={{ textAlign: "center" }}>
                        {" "}
                        This service is covered by the ProAct and Compliance
                        packages established as well as YHE’s ability to provide
                        development and certification support.
                        <br /> This includes: <br />
                        ISO audit programmes and certification support
                      </p>
                    </div>
                  }
                  event="#integrated"
                />
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      YHE can identify, support and supply equipment and
                      solutions to enhance operations and safety. This includes:
                    </p>
                  }
                  text={
                    <div>
                      {" "}
                      <li>Personal Protective Equipment</li>
                      <li>Lifesaving equipment</li>
                      <li>General Safety Equipment</li>
                      <li>Fire Fighting Equipment</li>
                      <li>
                        Fire detection and protection equipment <br /> <br />{" "}
                      </li>
                      <p style={{ textAlign: "center" }}>
                        This is further completed by the ProAct HSE services
                        offered by the JVs and training.
                      </p>
                    </div>
                  }
                  event="#supply_chain"
                />
                <Services
                  para={
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      Yellow Helmet Egypt and Blue star management systems offer
                      an all-inclusive ISO certification service accredited by
                      the UAF & IAF in which YHE prepares your enterprise
                      internally from the bottom-up including internal audits
                      for ISO certification
                    </p>
                  }
                  text={
                    <div>
                      {" "}
                      <li>ISO 9001</li>
                      <li>ISO 14001</li>
                      <li>ISO 45001</li>
                    </div>
                  }
                  event="#Iso"
                />
              </Tab.Content>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    );
  }
}

export default ourServices;
