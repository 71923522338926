import React from "react";
import Footer from "../footer/footer";
import Catalog from "./catalog";

import Iso from "./iso.jsx";
import NavBar from "../navBar/navBar";
import ApprovedByEgas from "./approvedbyEgas";
import "./styles.css";
const Supplies = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <div className=" suppliesPageContainer ">
        <NavBar />

        <h1 className="display-4 suppliesTitle ">
          Make Your Business a Safer Place with YHE Safety-HSE
        </h1>
      </div>
      <Catalog />
      <Iso />

      <ApprovedByEgas />

      <Footer />
    </div>
  );
};

export default Supplies;
