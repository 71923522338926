import "./NavBar.css";
import { React, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/img/logo.png";
import Scroll from "react-scroll";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

let ScrollLink = Scroll.ScrollLink;
let Link = Scroll.Link;

const NavBar = () => {
  const collapseRef = useRef(null);

  const hideBars = () => {
    collapseRef.current.setAttribute("class", "navbar-collapse collapse");
  };

  return (
    //className="p-3 mb-2 bg-dark text-white"  bg-dark
    <Navbar sticky="top" expand="lg" variant="dark" className=" navbar-dark">
      <div className="container" sticky="top">
        <Navbar.Brand className=" text-light">
          <NavLink to="/">
            <img src={logo} width="90px" alt="" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle className="sm-right" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          ref={collapseRef}
          id="navbarCollapse"
          className="sm-right"
          id="navbarCollapse"
        >
          <Nav className="navbar-nav  ms-auto  col-10 ">
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                Home
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/Clients" className="nav-link">
                Clients
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/code" className="nav-link">
                Accreditation Code
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/supplies" className="nav-link">
                Supplies and Equipments
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/digitalization" className="nav-link">
                IT and Digitalization
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/soonCourse" className="nav-link">
                Courses library
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/soonGallery" className="nav-link">
                Gallery
              </NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Nav.Item>
          <a
            target="_blank"
            className=" nav-link"
            rel="noreferrer"
            href="http://www.linkedin.com/in/yhe-company-97a953161"
          >
            <FontAwesomeIcon
              className="fa-l  text-white"
              icon={brands("linkedin")}
            />
          </a>
        </Nav.Item>
        <Nav.Item className="nav-link ">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/yellowhelmetegypt/"
          >
            <FontAwesomeIcon
              className="fa-l  text-white"
              icon={brands("instagram")}
            />
          </a>
        </Nav.Item>
        <Nav.Item className="nav-link ">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/YHEEgypt"
          >
            <FontAwesomeIcon
              className="fa-l  text-white"
              icon={brands("facebook")}
            />
          </a>
        </Nav.Item>
      </div>
    </Navbar>
  );
};

export default NavBar;
