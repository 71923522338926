import React, { useState } from "react";
import { Container } from "react-bootstrap";
import cataloge from "./pdf/YHE approved by EGAS_EGPC_updated.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button } from "react-bootstrap";
import "./styles.css";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ApprovedByEgas = () => {
  const [numPages, setNumPages] = useState(null);
  var [pageNumber, setPageNumber] = useState(1);
  function goToPrevPage() {
    if (pageNumber > 1) {
      pageNumber--;
      setPageNumber(pageNumber);
    }
  }
  function goToNextPage() {
    pageNumber++;
    setPageNumber(pageNumber);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div style={{ backgroundColor: "black" }}>
      {" "}
      <Container className="pdfContainer">
        <h1
          style={{ color: "#f9a91a" }}
          className="h1-responsive font-weight-bold text-center  mt-5 mb-3"
        >
          EGPC and EGAS Approval
        </h1>
        <div className="mydoc">
          <Document file={cataloge} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p className="text-white">
            Page {pageNumber} of {numPages}
            <Button
              variant="light"
              className="m-2 custom-btn btn-md"
              onClick={goToPrevPage}
            >
              <FontAwesomeIcon
                className="fa-xl"
                width={20}
                icon={solid("caret-left")}
              />
              Prev
            </Button>{" "}
            <Button
              variant="light"
              className="m-2 custom-btn btn-md"
              onClick={goToNextPage}
            >
              Next
              <FontAwesomeIcon
                className="fa-xl"
                width={20}
                icon={solid("caret-right")}
              />
            </Button>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ApprovedByEgas;
