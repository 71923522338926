import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/home/home";
import Clients from "./components/clients/clients";
import Accredation from "./components/accredation/accredation";
import It from "./components/it/it";
import NavBar from "./components/navBar/navBar";
import ComingSoon from "./components/comingSoon/comingSoon";
import Supplies from "./components/supplies/supplies";
// import Supplies1 from "./components/supplies/supplies1";
function App() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route exact path="/code" component={Accredation} />
          <Route exact path="/digitalization" component={It} />

          <Route exact path="/Supplies" component={Supplies} />
          <Route exact path="/clients" component={Clients} />
          <Route exact path="/soonCourse" component={ComingSoon} />
          <Route exact path="/soonGallery" component={ComingSoon} />
          <Route path="/" component={Home} />
        </Switch>
      </React.Fragment>
    </div>
  );
}

export default App;
