import React from "react";
import { Tab } from "react-bootstrap";
import "../partnerServices/styles.css";
const Services = (props) => {
  const { text, event, para } = props;
  return (
    <Tab.Pane className="fade show " eventKey={event}>
      <h5
        className=" h4 pane  "
        style={{ display: "inline-block", textAlign: "left" }}
      >
        <div className="m-2">{para}</div>
        <ul> {text}</ul>
      </h5>
    </Tab.Pane>
  );
};

export default Services;
