import React from "react";
import NavBar from "../navBar/navBar";
import who from "../../assets/img/who.png";
import { Container } from "react-bootstrap";
import Footer from "../footer/footer";
import "./coming.css";
const comingSoon = () => {
  return (
    <React.Fragment>
      <div>
        <div
          className="comingSoonPageContainer "
          style={{
            backgroundColor: "black",
          }}
        >
          <NavBar />
          <Container className="containerComing" style={{}}>
            <img src={who}  className="textBg" />
            <h1
              className="comingTitle centered"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Coming Soon{" "}
            </h1>
          </Container>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default comingSoon;
