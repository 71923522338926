import React from "react";
import { Button } from "react-bootstrap";
import "./accredation.css";
import NavBar from "../navBar/navBar";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../footer/footer";
const Accredation = () => {
  return (
    <React.Fragment>
      <div className="accredationPageContainer">
        <NavBar />
        <h1 className="display-4 accredationTitle">
          YHE Certificate verification
        </h1>
      </div>
      <div className="xbody">
        <div className="text-white text-center py-5">
          <div>
            <h2 className="card-title h1-responsive pt-3 mb-5 font-bold">
              <strong>Accreditation Code</strong>
            </h2>
            <h5>Note: Accreditation code is case sensitive</h5>

            <form>
              <div className="row">
                <div className="col codeContainer">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Example: Y-H-X-111-11-XX"
                    id="code"
                    pattern="[A-Z]*"
                  />
                </div>
              </div>
            </form>

            <Button className="custom-btn btn-md text-white" id="getcode">
              <FontAwesomeIcon
                className="fa-xl"
                width={50}
                icon={solid("graduation-cap")}
              />{" "}
              Get Certificate
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Accredation;
