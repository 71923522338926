import React, { Component } from "react";
import CompanyAndPartnersLogos from "../../assets/logosMix.png"; // CPL company and partners logos
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./styles.css";
class LandingPage extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Fade left>
          <Container className="landingPageContainer">
            <div className="row">
              <div className="col-lg ">
                <img
                  className="Logos"
                  src={CompanyAndPartnersLogos}
                  alt="company and partner logos"
                />
              </div>
              <div className="col-lg title ">
                <p>
                  Your Partner in HSE, Training, Consulting Technical Safety,
                  Verification and General Supplies
                </p>
                <p style={{ color: " #f9a91a" }}>
                  "The Real Enemy of Safety is not Non-Compliance but
                  Non-Thinking"
                  {/* We Build Nations Through Education - National Capacity
                  Building Model for Growing Countries */}
                </p>
              </div>
            </div>
          </Container>
        </Fade>
      </div>
    );
  }
}

export default LandingPage;
