import React from "react";
import yheApp from "../../assets/img/YHE app.jpeg";
import Footer from "../footer/footer";
import NavBar from "../navBar/navBar";
import "./styles.css";
const It = () => {
  return (
    <React.Fragment>
      <div
        className="view itPageContainer"
      
      >
        <NavBar />
        <div className="mask rgba-black-light d-flex justify-content-center align-items-center">
          <div className="container text-center d-block">
            <div className="row">
              <div className="col-6-sm mt-5 mx-auto">
                <h1 className="display-4 itTitle ">
                  Make Your Business Safer and more productive with YHE
                  IT-solutions
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main>
        <div className="container">
          <h2 className="h2-responsive  text-center mt-5 mb-3">
            Explore our digital transformation services Businesses across
            industries face increasing technological competition. Naturally,
            they respond by rethinking their digital strategies and adopting new
            technologies YHE onshore and offshore development teams help
            companies efficiently switch to digital rails and fill in any
            existing and future technological gaps.
          </h2>

          <img src={yheApp} className="img-fluid mx-auto d-block" />

          <h2 className="h2-responsive text-center mt-5 mb-3">
            Our analysts and engineers ensure digital transformation creates
            tangible business results, such as reduced costs, increased
            revenues, and better customer engagement. At{" "}
            <span className="font-weight-bold">YHE</span>, we provide extended
            teams and full-scale development centers to drive digital HSSE
            transformation strategies of our clients.
          </h2>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default It;
