import React, { Component } from "react";
import { Container } from "react-bootstrap";
import top from "../../assets/img/top.png";
import Fade from "react-reveal/Fade";

import "./styles.css";
class Overview extends Component {
  state = {};
  render() {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Container className="overviewContainer">
          <row>
            <Fade right>
              {" "}
              <div className="col-lg">
                <img className="theImg" src={top} />
              </div>
            </Fade>

            <div className="col-lg  ">
              <Fade left>
                <h1
                  style={{
                    color: "#f9a91a",
                    fontWeight: "bold",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  Who are we?
                </h1>
                <p className=" h4  theText text-center">
                  Yellow Helmet Egypt (YHE) and partners, Established in 2016
                  Our Partnerships and Associations: IADC, EGPC, EGAS, ECHEM,
                  GANOPE and General Authority for Investment & Free Zones
                </p>
              </Fade>
              <ul className="h5  " style={{ color: "white" }}>
                <Fade left>
                  <li>
                    YHE has a reputation for delivery quality services on time
                    and within budget
                  </li>
                  <li>
                    YHE and partners bring together a range of skill sets under
                    one management structure and delivery process
                  </li>
                  <li>
                    YHE and partners can provide wide ranging services that can
                    be tailored to meet a Clients Specific needs
                  </li>
                  <li>
                    Employees and Consultants are well qualified, experienced
                    and are all members of Professional Institutions
                  </li>
                  <li>
                    YHE and partners have the ability to provide the client with
                    a comprehensive skill base covering all aspects of risk
                    management, technical and occupational safety, integrity and
                    assurance across a wide range of Industrial and Commercial
                    applications
                  </li>
                  <li>Local based service </li>
                </Fade>
              </ul>
            </div>
          </row>
        </Container>
      </div>
    );
  }
}

export default Overview;
