import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import background from "../../assets/img/BottomBg.png";
import { Container } from "react-bootstrap";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import "./styles.css";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <hr className="my-4" />
      <Container className="footerContainer">
        <footer className="page-footer text-center font-small mt-4 wow fadeIn text-dark">
          <div className="pt-4">
            <div className="row">
              <div className="col-md-6 mt-md-4 mt-3">
                <h5 className="text-uppercase font-weight-bold text-dark">
                  Contact us
                </h5>
                <p className="footer-font">
                  <FontAwesomeIcon
                    className="fa-xl"
                    width={50}
                    icon={regular("envelope")}
                  />
                  <a href="mailto:info@yellowhelmetegypt.com">
                    <strong>
                      <span className="text-dark">
                        info@yellowhelmetegypt.com
                      </span>
                    </strong>
                  </a>
                </p>

                <p className="footer-font d-inline ">
                  {" "}
                  <FontAwesomeIcon
                    className="fa-xl"
                    icon={solid("mobile-screen-button")}
                  />
                  <a href="tel: 01120902070" style={{ color: "black" }}>
                    <strong> EG: 01120902070 </strong>
                  </a>
                  <a href="tel:  +44 79 7945 721" style={{ color: "black" }}>
                    <strong>, UK: +44 79 7945 721</strong>
                  </a>
                </p>
                <div className="m-2">
                  {" "}
                  <a href="tel: 02 27622073" style={{ color: "black" }}>
                    <FontAwesomeIcon
                      className="fa-xl mt-2 mr-2"
                      icon={solid("phone")}
                    />
                    <strong>02 27622073</strong>
                  </a>
                </div>
              </div>

              <hr className="clearfix w-100 d-md-none pb-3" />

              <div className="col-md-6 mt-md-4 mt-3">
                <h5 className="text-uppercase font-weight-bold">
                  <FontAwesomeIcon
                    className="fa-xl mr-6"
                    style={{ marginRight: "10px" }}
                    icon={solid("map-marked-alt")}
                  />
                  Our Locations
                </h5>

                <p className="footer-font">
                  <strong>Cairo Branch:</strong>
                  <a
                    href="https://goo.gl/maps/wo7wmmBFi11Qk6gH9"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark"
                  >
                    {" "}
                    Flat 7, Building 39, Group 6, Arabella Sq., Third District,
                    New Cairo, Egypt
                  </a>
                </p>
                <p className="footer-font ">
                  <strong>Guaranteed Workshops:</strong>
                  <a
                    href="https://goo.gl/maps/JRhHhxGWVfLxX8bs7"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark"
                  >
                    {" "}
                    PO 11435 41 Street 269 Third Sector-New Maadi, Floor 5,
                    Cairo, Egypt
                  </a>
                </p>
                <p className="footer-font">
                  <strong>UK Branch:</strong>
                  <a href="#" target="_blank" className="text-dark">
                    {" "}
                    Huddersfield, Snow island, King's mill lane. HD1 3AA
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="pb-4">
            <a
              href="https://www.facebook.com/YHEEgypt"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="fa-xl m-2 text-dark"
                icon={brands("facebook")}
              />
            </a>

            <a
              href="https://www.instagram.com/yellowhelmetegypt/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="fa-xl m-2 text-dark"
                icon={brands("instagram")}
              />
            </a>

            <a
              href="http://www.linkedin.com/in/yhe-company-97a953161"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="fa-xl m-2 text-dark"
                icon={brands("linkedin")}
              />
            </a>
          </div>
          <div className="row">
            <img src={background} className="d-block mx-auto" />
          </div>

          <div className="footer-copyright py-3">
            © 2022 Copyright:
            <a href="index.html" target="_blank" rel="noreferrer">
              {" "}
              yellowhelmetegypt.com{" "}
            </a>
          </div>
        </footer>
      </Container>
    </div>
  );
};

export default Footer;
