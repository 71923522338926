import React from "react";
import LandingPage from "../landingPage/landingPage";
import Overview from "../overview/overview";
import Vision from "../vision/vision";
import OurServices from "../ourServices/ourServices";
import PartnerServices from "../partnerServices/partnerServices";
import Footer from "../footer/footer";
import Quality from "../quality/quality";
import NavBar from "../navBar/navBar";
const Home = () => {
  return (
    <React.Fragment>
      <div style={{ backgroundColor: "black" }}>
        <NavBar />

        <LandingPage />
        <Overview />
        <Vision />
        <OurServices />
        <PartnerServices />
      </div>
      <Quality />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
