import React from "react";
import petrol from "../../assets/img/petrol.png";
import egpc from "../../assets/img/egpc.png";
import egas from "../../assets/img/egas.png";
import echem from "../../assets/img/echem.jpg";
import ganope from "../../assets/img/Ganope.jpg";
import gafi from "../../assets/img/GAFI.jpg";
import { Container } from "react-bootstrap";
const Quality = () => {
  return (
    <Container>
      <h1 className="my-5 h1-responsive text-center font-weight-bold">
        Quality or NOTHING!
      </h1>
      <h2 className="h2-responsive  text-center">
        We are approved/registered by..
      </h2>

      <div className="row mt-5 wow fadeIn mb-3">
        <div className="col-xl-12 col-lg-4 mb-5">
          <img
            src={petrol}
            className="img-fluid text-center mx-auto d-block egas-img"
            alt="egas"
          />
        </div>
        <div className="col-xl-3 col-lg-4 mb-5">
          <img
            src={egpc}
            className="img-fluid text-center mx-auto d-block egpc-img"
            alt="egpc"
          />
        </div>
        <div className="col-xl-3 col-lg-4 mb-5">
          <img
            src={egas}
            className="img-fluid text-center mx-auto d-block egpc-img"
            alt="egpc"
          />
        </div>

        <div className="col-xl-3 col-lg-4 mb-5">
          <img
            src={echem}
            className="img-fluid text-center mx-auto d-block pt-5 "
            width="50%"
            alt="echem"
          />
        </div>

        <div className="col-xl-3 col-lg-4 mb-5">
          <img
            src={ganope}
            className="img-fluid text-center mx-auto d-block iso-img"
            alt="ganope"
          />
        </div>

        <div className="col-xl-12 col-lg-4 mb-5">
          <img
            src={gafi}
            className="img-fluid text-center mx-auto d-block iso-img"
            alt="gafi"
          />
        </div>
      </div>

      <div id="test"></div>
    </Container>
  );
};

export default Quality;
