import React from "react";

import Footer from "../footer/footer";
import FirstPage from "./firstPage";
import OurClients from "./ourClients";
const Clients = () => {
  return (
    <React.Fragment>
      <FirstPage />
      <h1
        style={{ marginBottom: "-70px", marginTop: "40px" }}
        className="text-center m-9"
      >
        {" "}
        Our Clients{" "}
      </h1>{" "}
      <br />
      <OurClients />
      <Footer />
    </React.Fragment>
  );
};

export default Clients;
